import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DateInput,
  required,
  useRedirect,
} from 'react-admin';

export default function CreateProject(props) {
  const redirect = useRedirect();
  return (
    <Create
      title="Create a project"
      {...props}
      onSuccess={() => redirect('/projects')}
    >
      <SimpleForm>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="clientName" />
        <DateInput source="startedAt" />
        <TextInput source="techStack" validate={[required()]} />
        <TextInput source="description" multiline />
        <TextInput
          source="features"
          multiline
          helperText="Coma separated list"
        />
      </SimpleForm>
    </Create>
  );
}
