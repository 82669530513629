import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton,
  DateInput,
  required,
} from 'react-admin';

const EditProjectToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};

export default function EditProject(props) {
  return (
    <Edit title="Edit project" {...props}>
      <SimpleForm toolbar={<EditProjectToolbar />}>
        <TextInput source="title" validate={[required()]} />
        <TextInput source="clientName" />
        <DateInput source="startedAt" />
        <TextInput source="techStack" validate={[required()]} />
        <TextInput source="description" multiline />
        <TextInput
          source="features"
          multiline
          helperText="Coma separated list"
        />
      </SimpleForm>
    </Edit>
  );
}
