import React from 'react';
import { Admin, Resource } from 'react-admin';
import restWithFileUploadProvider from '../dataProviders/restWithFileUploadProvider';
import authProvider from '../dataProviders/authProvider';

import EditProject from './Projects/EditProject';
import ProjectsList from './Projects/ProjectsList';
import CreateProject from './Projects/CreateProject';

export default () => (
  <Admin dataProvider={restWithFileUploadProvider} authProvider={authProvider}>
    <Resource
      name="projects"
      create={CreateProject}
      list={ProjectsList}
      edit={EditProject}
    />
  </Admin>
);
