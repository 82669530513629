import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
} from 'react-admin';

export default function ProjectsList(props) {
  return (
    <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <TextField source="title" />
        <TextField source="clientName" />
        <TextField source="techStack" />
        <TextField source="description" />
        <TextField source="features" />
        <DateField source="startedAt" />
        <DateField source="createdAt" />
        <EditButton basePath="/projects" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}
